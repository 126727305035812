const EMAIL_PATTERN =
  /^[\w!#$%&'*+-/=?^_`{|}~]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/;
const VA_EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@va.gov$/;
const PRESENCE_PATTERN = /^(?!\s*$).+/;
const PARTIAL_URL_PATTERN = /^http[s]?:[/][/][^/:?#]+(:[0-9]+)?([/][^?#]*)?$/;
const SPACES_PRESENT_PATTERN = /^[^\s]*$/;

export const validatePresence = (fieldName: string, value: string): string | undefined => {
  if (!PRESENCE_PATTERN.test(value)) {
    return `Enter your ${fieldName}.`;
  }

  return undefined;
};

export const validateOAuthApplicationType = (value: string): string | undefined => {
  if (!PRESENCE_PATTERN.test(value)) {
    return 'Choose an option.';
  }

  return undefined;
};

export const validateEmail = (value: string): string | undefined => {
  if (!EMAIL_PATTERN.test(value)) {
    return 'Enter a valid email address.';
  }

  return undefined;
};

export const isVaEmail = (value: string): boolean => VA_EMAIL_PATTERN.test(value);

export const validateVAEmail = (value: string): string | undefined => {
  if (!isVaEmail(value)) {
    return 'Enter a valid VA-issued email address.';
  }

  return undefined;
};

export const validateOAuthRedirectURI = (value: string): string | undefined => {
  if (!PARTIAL_URL_PATTERN.test(value)) {
    return 'Enter an http or https URI.';
  }

  if (!SPACES_PRESENT_PATTERN.test(value)) {
    return 'Enter just the redirect URI.';
  }

  return undefined;
};

export const validateOAuthPublicKey = async (value: string): Promise<string | undefined> => {
  try {
    const jwk = JSON.parse(value, (_, keyValue) => {
      if (keyValue !== null) {
        return keyValue as string;
      }
      return undefined;
    }) as { [key: string]: string };
    const jwkKeys = Object.keys(jwk);
    // https://auth0.com/docs/secure/tokens/json-web-tokens/json-web-key-set-properties
    const allowedKeys = ['alg', 'kty', 'use', 'x5c', 'n', 'e', 'kid', 'x5t'];
    if (
      !jwk.kty ||
      jwk.kty !== 'RSA' ||
      !jwkKeys.every(key => allowedKeys.includes(key)) ||
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      jwk.kid?.length > 255
    ) {
      return 'Please enter a valid RSA-generated key in JSON Web Key format.';
    }

    const alg = {
      hash: 'SHA-256',
      name: 'RSASSA-PKCS1-v1_5',
    };
    const importedJWK = await window.crypto.subtle.importKey('jwk', jwk, alg, false, ['verify']);
    if (importedJWK.type !== 'public' || importedJWK.algorithm.name !== alg.name) {
      return 'Please enter a valid RSA-generated key in JSON Web Key format.';
    }

    return undefined;
  } catch (e: unknown) {
    return 'Please enter a valid RSA-generated key in JSON Web Key format.';
  }
};
