import React, { FC } from 'react';
import { CodeBlock } from '../../../components';

interface GeneratingJWTProps {
  apiName: string;
  productionAud: string;
  sandboxAud: string;
}

const GeneratingJWTContent: FC<GeneratingJWTProps> = ({ apiName, productionAud, sandboxAud }) => (
  <>
    <h3 id="generating-signing-jwt" tabIndex={-1}>
      Generating and signing the JWT
    </h3>
    <p>Generate your JWT using:</p>
    <ul>
      <li>The client ID we sent you by email.</li>
      <li>
        The token recipient URL (<code>aud</code>). Check the table below for the values to use for
        this API.
      </li>
      <li>Your RSA private key, for signing the client assertion.</li>
    </ul>
    <p>This table describes the claims you will use in your JWT and client assertion:</p>

    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            <th>Claim</th>
            <th>Required</th>
            <th>Description</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <code>aud</code>
            </td>
            <td>True</td>
            <td>
              <p>
                <strong>String.</strong> The recipient URL (audience) where you will send your
                token. For the {apiName}, these are:
              </p>
              <ul>
                <li>
                  <span className="vads-u-font-weight--bold">Sandbox:</span>{' '}
                  {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                  <pre tabIndex={0}>
                    <code>{`https://deptva-eval.okta.com/oauth2/${sandboxAud}/v1/token`}</code>
                  </pre>
                </li>
                {sandboxAud !== productionAud && (
                  <li>
                    <span className="vads-u-font-weight--bold">Production:</span>{' '}
                    {/* eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex */}
                    <pre tabIndex={0}>
                      <code>{`https://va.okta.com/oauth2/${productionAud}/v1/token`}</code>
                    </pre>
                  </li>
                )}
              </ul>
            </td>
          </tr>
          <tr>
            <td>
              <code>iss</code>
            </td>
            <td>True</td>
            <td>
              <strong>String.</strong> The client ID we sent you when you signed up for sandbox
              access.
            </td>
          </tr>
          <tr>
            <td>
              <code>sub</code>
            </td>
            <td>True</td>
            <td>
              <strong>String.</strong> The client ID we sent you when you signed up for sandbox
              access.
            </td>
          </tr>
          <tr>
            <td>
              <code>iat</code>
            </td>
            <td>False</td>
            <td>
              <strong>Integer.</strong> A timestamp for how many seconds have passed since January
              1, 1970 UTC. It must be a time before the request occurs.{' '}
              <p>
                <code>
                  <span className="vads-u-font-weight--bold">Example:</span> 1604429781
                </code>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <code>exp</code>
            </td>
            <td>True</td>
            <td>
              <strong>Integer.</strong> A timestamp for when the token will expire, given in seconds
              since January 1, 1970. This claim fails the request if the expiration time is more
              than 300 seconds (5 minutes) after the iat.{' '}
              <p>
                <code>
                  <span className="vads-u-font-weight--bold">Example:</span> 1604430081
                </code>
              </p>
            </td>
          </tr>
          <tr>
            <td>
              <code>jti</code>
            </td>
            <td>False</td>
            <td>
              <strong>String.</strong> A unique token identifier. If you specify this parameter, the
              token can only be used once and, as a result, subsequent token requests won&apos;t
              succeed.{' '}
              <p>
                <code>
                  <span className="vads-u-font-weight--bold">Example:</span>{' '}
                  23f8f614-72c3-4267-b0da-b8b067662c74
                </code>
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <p>
      The following example shows how a JWT could be generated and signed with a NodeJS function
      using the{' '}
      <a href="https://www.npmjs.com/package/njwt" target="_blank" rel="noopener noreferrer">
        nJwt
      </a>{' '}
      library:
    </p>
    <CodeBlock
      withCopyButton
      language="javascript"
      code={`function getAssertionPrivatekey (clientId, key, audience) {
  let secondsSinceEpoch = Math.round(Date.now() / 1000);
  const claims = { 
    "aud": audience,
    "iss": clientId,
    "sub": clientId,
    "iat": secondsSinceEpoch,
    "exp": secondsSinceEpoch + 3600,
    "jti": crypto.randomUUID()
  };
  let secret = fs.readFileSync(key, "utf8");
  let algorithm = "RS256";
  const token = jwt.create(claims, secret, algorithm); 
  return token.compact();
}`}
    />
  </>
);

export default GeneratingJWTContent;
