/* eslint-disable @typescript-eslint/no-invalid-this */
import * as yup from 'yup';
import { StringSchema } from 'yup';

yup.addMethod<StringSchema>(yup.string, 'isValidRSAJWK', function () {
  return this.test('isValidRSAJWK', async function (value: string) {
    const { path, createError } = this;

    try {
      const jwk = JSON.parse(value, (_, keyValue) => {
        if (keyValue !== null) {
          return keyValue as string;
        }
        return undefined;
      }) as { [key: string]: string };
      const jwkKeys = Object.keys(jwk);
      // https://auth0.com/docs/secure/tokens/json-web-tokens/json-web-key-set-properties
      const allowedKeys = ['alg', 'kty', 'use', 'x5c', 'n', 'e', 'kid', 'x5t'];
      if (
        !jwk.kty ||
        jwk.kty !== 'RSA' ||
        !jwkKeys.every(key => allowedKeys.includes(key)) ||
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        jwk.kid?.length > 255
      ) {
        return createError({
          message: 'Please enter a valid RSA-generated key in JSON Web Key format.',
          path,
        });
      }

      const alg = {
        hash: 'SHA-256',
        name: 'RSASSA-PKCS1-v1_5',
      };
      const importedJWK = await window.crypto.subtle.importKey('jwk', jwk, alg, false, []); // returning false in tests
      if (importedJWK.type !== 'public' || importedJWK.algorithm.name !== alg.name) {
        return createError({
          message: 'Please enter a valid RSA-generated key in JSON Web Key format.',
          path,
        });
      }

      return true;
    } catch (e: unknown) {
      return createError({
        message: 'Please enter a valid RSA-generated key in JSON Web Key format.',
        path,
      });
    }
  });
});

// Override Yup's email validator
const emailRegex =
  /^[\w!#$%&'*+-/=?^_`{|}~]+(?:\.[\w!#$%&'*+/=?`{|}~^-]+)*@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,63}$/;
yup.addMethod(yup.string, 'email', function (message: string) {
  return this.matches(emailRegex, {
    excludeEmptyString: true,
    message,
    name: 'email',
  });
});

export const stringSchema = yup.string().isValidRSAJWK();

declare module 'yup' {
  interface StringSchema {
    isValidRSAJWK: () => StringSchema;
  }
}

export default yup;
